import React from 'react'
import PropTypes from 'prop-types'
import { removeTrailingSlash } from '../../utils'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faCode, faPlay } from '@fortawesome/free-solid-svg-icons'

import './MusicSummary.sass'

const MusicSummary = ({
  slug,
  title,
  instrumentation,
  compositionDate,
  duration,
  media,
  score,
  code,
  excerpt,
}) => (
  <section className={`music-summary`}>
    <Link className="music-summary-link" to={removeTrailingSlash(slug)}>
      <h3 className="music-summary-title">{title}</h3>
    </Link>
    <div className="music-summary-meta">
      <span className="music-summary-meta-item">{compositionDate}</span>
      <span className="music-summary-instrumentation music-summary-meta-item">
        {instrumentation.join(', ')}
      </span>
      <span className="music-summary-duration music-summary-meta-item">
        Duration: {duration}
      </span>
      {((!!media && !!media.length) || !!score || !!code) && (
        <span className="music-summary-meta-item music-summary-meta-icons">
          {!!score && (
            <Link
              title="Score available"
              to={slug}
              className="music-summary-meta-icon-link"
            >
              <FontAwesomeIcon
                icon={faMusic}
                className="music-summary-meta-icon fa-sm"
                style={{ marginRight: '2px' }}
              />
            </Link>
          )}
          {!!code && (
            <Link
              title="Source code available"
              to={slug}
              className="music-summary-meta-icon-link"
            >
              <FontAwesomeIcon
                icon={faCode}
                className="music-summary-meta-icon fa-sm"
              />
            </Link>
          )}
          {!!media && !!media.length && (
            <Link
              title="Audio/visual media available"
              to={slug}
              className="music-summary-meta-icon-link"
            >
              <FontAwesomeIcon
                icon={faPlay}
                className="music-summary-meta-icon fa-sm"
                style={{ marginLeft: '1px' }}
              />
            </Link>
          )}
        </span>
      )}
    </div>
    <div className="music-summary-excerpt">{excerpt}</div>
    <Link to={slug} className="music-summary-cta button">
      Piece Details
    </Link>
  </section>
)

export const musicSummaryProps = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  instrumentation: PropTypes.arrayOf(PropTypes.string).isRequired,
  compositionDate: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  media: PropTypes.arrayOf(PropTypes.string),
  score: PropTypes.string,
  code: PropTypes.string,
}

MusicSummary.propTypes = musicSummaryProps

export default MusicSummary
